import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import StorygramGUI from './components/StorygramWrapper';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "installation"
    }}>{`Installation`}</h1>
    <p>{`Welcome to the Storygram Documentation!`}</p>
    <h2 {...{
      "id": "usage-with-typescript"
    }}>{`Usage with Typescript`}</h2>
    <p>{`Import the Storygram module and the Config type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`import { Storygram, Config } from 'storygram'
`}</code></pre>
    <p>{`Then define your data:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`const data = [
    { event: 1, actors: ['a', 'b', 'c'] },
    { event: 2, actors: ['d', 'b', 'e'] },
    { event: 3, actors: ['d', 'a'] }
]
`}</code></pre>
    <p>{`Define your configuration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`const config: Config = {
    dataFormat: 'array',
    eventField: 'event',
    actorArrayField: 'actors',
}
`}</code></pre>
    <p>{`Then create a Storygram object with your data and configuration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`let storyGram = new Storygram(data, config)
`}</code></pre>
    <p>{`Finally, draw your Storygram:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`storyGram.draw();
`}</code></pre>
    <p>{`The complete code looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`import { Storygram, Config } from 'storygram'

const data = [
    { event: 1, actors: ['a', 'b', 'c'] },
    { event: 2, actors: ['d', 'b', 'e'] },
    { event: 3, actors: ['d', 'a'] }
]

const config: Config = {
    dataFormat: 'array',
    eventField: 'event',
    actorArrayField: 'actors',
}

let storyGram = new Storygram(data, config)
storyGram.draw();
`}</code></pre>
    <h2 {...{
      "id": "usage-with-nodejs-without-typescript"
    }}>{`Usage with node.js without Typescript`}</h2>
    <p>{`To do the same with node.js without Typescript type:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`var Storygram = require('storygram');

var data = [
    { event: 1, actors: ['a', 'b', 'c'] },
    { event: 2, actors: ['d', 'b', 'e'] },
    { event: 3, actors: ['d', 'a'] }
]

var config = {
    dataFormat: 'array',
    eventField: 'event',
    actorArrayField: 'actors',
}

var storyGram = new Storygram(data, config)
storyGram.draw();
`}</code></pre>
    <h2 {...{
      "id": "embedding-in-html-page"
    }}>{`Embedding in HTML page`}</h2>
    <p>{`To embed the Storygram in a html page type:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`(...)
  <body>
    <h1>My first Storygram!</h1>

    <script src="https://unpkg.com/storygram/dist/storygram.min.js"></script>
    <script>
      const data = [
        { event: 1, actors: ['a', 'b', 'c'] },
        { event: 2, actors: ['d', 'b', 'e'] },
        { event: 3, actors: ['d', 'a'] },
      ];
      const config = {
        dataFormat: 'array',
        eventField: 'event',
        actorArrayField: 'actors',
      };
      let storyGram = new Storygram.Storygram(data, config);
      storyGram.draw();
    </script>
  </body>
(...)
`}</code></pre>
    <p>{`It will append a 'svg' for the storyGram and a 'div' for the tooltip.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      